html,
body {
    height: 100%;
    font-family: "Roboto Condensed", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: #111111;
    font-weight: 400;
    font-family: "Roboto Condensed", sans-serif;
}

h1 {
    font-size: 70px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: 16px;
    font-family: "Roboto Condensed", sans-serif;
    color: #636363;
    font-weight: 400;
    line-height: 24px;
    margin: 0.5px 1px 15px 10px;
}

img {
    max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
    outline: none;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
    color: #fff;
}

ul,
ol {
    padding: 0;
    margin: 0;
}


/*---------------------
  Helper CSS
-----------------------*/

.section-title {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 30px;
}

.section-title.sidebar-title:before {
    height: 2px;
}

.section-title.sidebar-title:after {
    height: 2px;
}

.section-title.sidebar-title h5 {
    font-size: 20px;
    color: #151618;
    font-weight: 500;
}

.section-title:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 70px;
    background: #c5141f;
    content: "";
    z-index: 1;
}

.section-title:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background: #f2f2f2;
    content: "";
}

.section-title h3 {
    font-size: 28px;
    color: #151618;
    font-weight: 300;
}

.section-title h3 span {
    font-weight: 700;
}

.set-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.spad {
    padding-top: 100px;
    padding-bottom: 100px;
}

.spad-2 {
    padding-top: 50px;
    padding-bottom: 70px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
    color: #fff;
}


/* buttons */

.primary-btn {
    font-size: 16px;
    color: #ffffff;
    background: #162330;
    font-weight: 500;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 36px 12px;
}


/* Preloder */

#preloder {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #000;
}

.loader {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    border-radius: 60px;
    animation: loader 0.8s linear infinite;
    -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        border: 4px solid #673ab7;
        border-left-color: transparent;
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
    50% {
        -webkit-transform: rotate(180deg);
        border: 4px solid #673ab7;
        border-left-color: transparent;
    }
    100% {
        -webkit-transform: rotate(360deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
}


/* Search Option */

.spacial-controls {
    position: fixed;
    width: 111px;
    height: 91px;
    top: 0;
    right: 0;
    z-index: 999;
}

.spacial-controls .search-switch {
    display: block;
    height: 100%;
    padding-top: 30px;
    background: #323232;
    text-align: center;
    cursor: pointer;
}

.search-model {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    z-index: 99999;
}

.search-model-form {
    padding: 0 15px;
}

.search-model-form input {
    width: 500px;
    font-size: 40px;
    border: none;
    border-bottom: 2px solid #333;
    background: none;
    color: #999;
}

.search-close-switch {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #333;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    font-size: 28px;
    line-height: 28px;
    top: 30px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


/*---------------------
  Header
-----------------------*/

.header__top .ht-info ul li {
    list-style: none;
    display: inline-block;
    margin-right: 35px;
    position: relative;
    font-size: 14px;
    color: #2d2d2d;
    padding: 14px 0 15px;
}

.header__top .ht-info ul li:after {
    position: absolute;
    right: -20px;
    top: 14px;
    width: 1px;
    height: 20px;
    background: #e5e5e5;
    content: "";
}

.header__top .ht-info ul li:last-child:after {
    display: none;
}

.header__top .ht-info ul li a {
    font-size: 14px;
    color: #2d2d2d;
}

.header__top .ht-links {
    text-align: right;
}

.header__top .ht-links a {
    display: inline-block;
    font-size: 14px;
    color: #2d2d2d;
    margin-left: 20px;
    padding: 14px 0 15px;
}

.header__nav {
    background: #fefefe;
}

.header__nav .logo {
    padding: 30px 0;
}

.header__nav .logo a {
    display: inline-block;
}

.header__nav .nav-menu {
    text-align: right;
}

.header__nav .nav-menu .main-menu {
    display: inline-block;
}

.header__nav .nav-menu .main-menu li {
    display: inline-block;
    list-style: none;
    margin-right: 40px;
    position: relative;
}

.header__nav .nav-menu .main-menu li .dropdown {
    position: absolute;
    left: 0;
    top: 115px;
    width: 180px;
    background: #ffffff;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.header__nav .nav-menu .main-menu li .dropdown li {
    margin-right: 0;
    display: block;
    text-align: left;
}

.header__nav .nav-menu .main-menu li .dropdown li a {
    padding: 5px 20px;
    display: block;
    color: #151618;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    text-transform: capitalize;
}

.header__nav .nav-menu .main-menu li .dropdown li a:hover {
    color: #162330;
}

.header__nav .nav-menu .main-menu li .dropdown li a:after {
    display: none;
}

.header__nav .nav-menu .main-menu li.active a:after {
    opacity: 1;
}

.header__nav .nav-menu .main-menu li:hover a:after {
    opacity: 1;
}

.header__nav .nav-menu .main-menu li:hover .dropdown {
    opacity: 1;
    visibility: visible;
    top: 95px;
}

.header__nav .nav-menu .main-menu li a {
    font-size: 15px;
    color: #c5141f;
    font-weight: 600;
    display: inline-block;
    text-transform: uppercase;
    padding: 20px 0;
    position: relative;
}

.header__nav .nav-menu .main-menu li a:after {
    position: absolute;
    left: 0;
    top: 50px;
    height: 2px;
    width: 100%;
    background: #ffffff;
    content: "";
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
}

.header__nav .nav-menu .nm-right {
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
}

.header__nav .nav-menu .nm-right i {
    font-size: 14px;
    color: #ffffff;
}

.canvas-open {
    display: none;
}

.offcanvas-menu-wrapper {
    display: none;
}


/*---------------------
  Hero
-----------------------*/

.hero-section {
    height: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.hs-item .hs-text {
    text-align: center;
}

.hs-item .hs-text h4 {
    color: #ffffff;
    margin-bottom: 25px;
}

.hs-item .hs-text h2 {
    font-size: 48px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 32px;
}

.foottertitle {
    color: #c5141f !important;
    font-weight: 550;
}


/*---------------------
  Trending News
-----------------------*/

.trending-news-section {
    background: #151618;
    position: relative;
    padding: 18px 0;
}

.trending-news-section .tn-title {
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
    background: #162330;
    text-align: right;
    padding-right: 75px;
    padding-top: 14px;
    width: 32%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
}

.trending-news-section .news-slider.owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    top: 0;
}

.trending-news-section .news-slider.owl-carousel .owl-nav button {
    height: 22px;
    width: 22px;
    border: 1px solid #8a8b8c;
    text-align: center;
    font-size: 12px;
    color: #ababab;
    line-height: 22px;
    margin-left: 10px;
    background: #151618;
}

.trending-news-section .news-slider .nt-item {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    padding-left: 305px;
}


/*---------------------
  Match Section
-----------------------*/

.match-section {
    padding: 70px 0 60px;
}

.ms-content h4 {
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 25px;
}

.ms-content .mc-table {
    margin-bottom: 10px;
}

.ms-content .mc-table table {
    width: 100%;
}

.ms-content .mc-table table tr {
    background: rgba(21, 22, 24, 0.9);
    margin-bottom: 10px;
}

.ms-content .mc-table table tr td {
    padding: 16px 0;
    width: 200px;
}

.ms-content .mc-table table tr td img {
    height: 30px;
    width: 50px;
}

.ms-content .mc-table table tr td h6 {
    color: #ffffff;
    display: inline-block;
}

.ms-content .mc-table table tr td.left-team {
    padding-left: 25px;
}

.ms-content .mc-table table tr td.left-team img {
    float: left;
    margin-right: 10px;
}

.ms-content .mc-table table tr td.left-team h6 {
    overflow: hidden;
    line-height: 30px;
}

.ms-content .mc-table table tr td.right-team {
    text-align: right;
    padding-right: 40px;
}

.ms-content .mc-table table tr td.right-team img {
    float: right;
    margin-left: 10px;
}

.ms-content .mc-table table tr td.right-team h6 {
    float: right;
    line-height: 30px;
}

.ms-content .mc-table table tr td.mt-content {
    width: 150px;
    text-align: center;
}

.ms-content .mc-table table tr td.mt-content .mc-op {
    font-size: 14px;
    color: #ffffff;
}

.ms-content .mc-table table tr td.mt-content .mc-op-golden {
    font-size: 14px;
    color: #eeca42;
}

.ms-content .mc-table table tr td.mt-content h4 {
    color: #ffffff;
    font-weight: 700;
    margin-top: 14px;
    margin-bottom: 12px;
}


/*---------------------
  Soccer Section
-----------------------*/

.soccer-section {
    padding: 70px 0;
}

.soccer-section .col-lg-12 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.soccer-section .col-lg-3 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.soccer-item {
    height: 405px;
    position: relative;
    margin-bottom: 10px;
}

.soccer-item .si-tag {
    color: #ffffff;
    background: #162330;
    font-size: 10px;
    font-weight: 500;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px 9px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.soccer-item .si-text {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    padding: 0 30px;
}

.soccer-item .si-text h5 {
    margin-bottom: 6px;
}

.soccer-item .si-text h5 a {
    color: #ffffff;
    font-weight: 500;
    line-height: 26px;
}

.soccer-item .si-text ul li {
    list-style: none;
    display: inline-block;
    font-size: 13px;
    color: #ffffff;
    margin-right: 20px;
    position: relative;
}

.soccer-item .si-text ul li:after {
    position: absolute;
    right: -12px;
    top: 0;
    content: "|";
}

.soccer-item .si-text ul li:last-child {
    margin-right: 0;
}

.soccer-item .si-text ul li:last-child:after {
    display: none;
}


/*---------------------
  Latest Section
-----------------------*/

.latest-section {
    padding-bottom: 30px;
}

.section-title.latest-title h3 {
    float: left;
}

.section-title.latest-title ul {
    text-align: right;
}

.section-title.latest-title ul li {
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #151618;
    padding: 4px 12px;
    background: #f2f2f2;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-right: 5px;
    cursor: pointer;
}

.section-title.latest-title ul li:last-child {
    margin-right: 0;
}

.section-title.latest-title ul li:hover {
    background: #162330;
    color: #ffffff;
}

.news-item {
    overflow: hidden;
    margin-bottom: 30px;
    cursor: pointer;
}

.news-item.left-news .ni-pic {
    height: 204px;
    width: auto;
    position: relative;
    float: none;
}

.news-item.left-news .ni-pic .ni-tag {
    color: #ffffff;
    background: #162330;
    font-size: 10px;
    font-weight: 500;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px 9px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.news-item.left-news .ni-text {
    padding-top: 25px;
    overflow: visible;
}

.news-item.left-news .ni-text h4 {
    margin-bottom: 15px;
}

.news-item.left-news .ni-text h4 a {
    color: #151618;
    line-height: 32px;
    font-weight: 500;
}

.news-item.left-news .ni-text ul {
    margin-bottom: 20px;
}

.news-item.left-news .ni-text p {
    font-size: 14px;
    color: #636363;
    line-height: 24px;
}

.news-item .ni-pic {
    float: left;
    margin-right: 20px;
}

.news-item .ni-pic img {
    height: auto;
    width: 206px;
}

.news-item .ni-text {
    overflow: hidden;
    padding-top: 6px;
}

.news-item .ni-text h5 {
    margin-bottom: 8px;
}

.news-item .ni-text h5 a {
    color: #151618;
    font-weight: 500;
    line-height: 26px;
    display: block;
}

.news-item .ni-text ul li {
    list-style: none;
    display: inline-block;
    font-size: 14px;
    color: #ababab;
    margin-right: 20px;
    position: relative;
}

.news-item .ni-text ul li i {
    color: #162330;
}

.news-item .ni-text ul li:after {
    position: absolute;
    right: -12px;
    top: 0;
    content: "|";
}

.news-item .ni-text ul li:last-child {
    margin-right: 0;
}

.news-item .ni-text ul li:last-child:after {
    display: none;
}

.points-table table {
    width: 100%;
}

.points-table table thead tr th {
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    background: #2c2d2f;
    padding: 14px 0 13px;
}

.points-table table thead tr th.th-o {
    text-align: center;
}

.points-table table tbody {
    border: 1px solid #e5e5e5;
    border-top: none;
    border-bottom: none;
}

.points-table table tbody tr {
    border-bottom: 1px solid #e7e7e7;
}

.points-table table tbody tr td {
    text-align: center;
    font-size: 14px;
    color: #636363;
    padding: 12px 0;
}

.points-table table tbody tr td.team-name {
    text-align: left;
    width: 130px;
}

.points-table table tbody tr td.team-name img {
    height: 22px;
    width: 36px;
    margin-right: 6px;
    display: inline-block;
}

.points-table table tbody tr td.team-name span {
    display: inline-block;
    font-size: 14px;
    color: #332;
    font-weight: 500;
}

.points-table .p-all {
    font-size: 14px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    background: #f2f2f2;
    display: block;
    text-align: center;
    padding: 15px;
}


/*---------------------
  Video Section
-----------------------*/

.video-section {
    margin-top: 10px;
    margin-bottom: 35px;
}

.video-slider.owl-carousel {
    margin-right: 0;
}

.video-slider.owl-carousel .col-lg-3 {
    max-width: 100%;
}

.video-slider.owl-carousel .owl-nav {
    position: absolute;
    right: 15px;
    top: -78px;
}

.video-slider.owl-carousel .owl-nav button {
    font-size: 20px;
    color: #636363;
    width: 30px;
    height: 30px;
    border: 1px solid #d5d5d5;
    line-height: 30px;
    text-align: center;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-right: 10px;
}

.video-slider.owl-carousel .owl-nav button:last-child {
    margin-right: 0;
}

.video-slider.owl-carousel .owl-nav button:hover {
    background: #162330;
    color: #ffffff;
    border-color: #162330;
}

.video-item {
    height: 200px;
    position: relative;
}

.video-item:hover .play-btn {
    opacity: 1;
    visibility: visible;
}

.video-item .vi-title {
    position: absolute;
    left: 0;
    top: 15px;
    width: 100%;
    padding: 0 20px;
}

.video-item .vi-title h5 {
    color: #ffffff;
    font-weight: 500;
    line-height: 26px;
}

.video-item .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-20px, -20px);
    -ms-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.video-item .vi-time {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 1px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.7);
    padding: 3px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}


/*---------------------
  Popurlar News Section
-----------------------*/

.news-item.popular-item {
    height: 240px;
    position: relative;
}

.news-item.popular-item .ni-tag {
    color: #ffffff;
    background: #162330;
    font-size: 10px;
    font-weight: 500;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px 9px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.news-item.popular-item .ni-tag.tenis {
    background: #0054a6;
}

.news-item.popular-item .ni-tag.football {
    background: #e3ce1e;
}

.news-item.popular-item .ni-text {
    padding-top: 0;
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
    padding: 0 20px;
}

.news-item.popular-item .ni-text h5 a {
    color: #ffffff;
}

.news-item.popular-item .ni-text ul li {
    color: #ffffff;
}

.news-item.popular-item .ni-text ul li i {
    color: #ffffff;
}

.follow-links {
    margin-bottom: 70px;
}

.follow-links ul li {
    list-style: none;
    overflow: hidden;
    padding: 12px 16px 10px 20px;
    margin-bottom: 10px;
}

.follow-links ul li.facebook {
    background: #506eaa;
}

.follow-links ul li.twitter {
    background: #55acee;
}

.follow-links ul li.google {
    background: #dd4b39;
}

.follow-links ul li i {
    font-size: 14px;
    color: #ffffff;
    float: left;
    line-height: 24px;
}

.follow-links ul li .fl-name {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    float: left;
    margin-left: 32px;
    position: relative;
}

.follow-links ul li .fl-name:after {
    position: absolute;
    left: -15px;
    top: -2px;
    height: 26px;
    width: 1px;
    background: #ffffff;
    content: "";
}

.follow-links ul li .fl-fan {
    font-size: 14px;
    color: #ffffff;
    float: right;
}

.vote-option {
    height: 290px;
}

.vote-option .vo-text {
    height: 100%;
    padding-left: 30px;
    padding-top: 42px;
}

.vote-option .vo-text h5 {
    color: #ffffff;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 20px;
}

.vote-option .vo-text .vt-item {
    margin-bottom: 4px;
}

.vote-option .vo-text .vt-item input {
    position: absolute;
    visibility: hidden;
}

.vote-option .vo-text .vt-item label {
    font-size: 16px;
    color: #ffffff;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
}

.vote-option .vo-text .vt-item label:before {
    position: absolute;
    left: 0;
    top: 4px;
    width: 14px;
    height: 14px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    content: "";
}

.vote-option .vo-text .vt-item input[type=radio]:checked+label:before {
    background: #ffffff;
}


/*---------------------
  Footer
-----------------------*/

.footer-section {
    padding-top: 60px;
}

.fs-logo {
    margin-bottom: 30px;
}

.fs-logo .logo {
    margin-bottom: 30px;
}

.fs-logo .logo a {
    display: inline-block;
}

.fs-logo ul {
    margin-bottom: 22px;
}

.fs-logo ul li {
    font-size: 16px;
    color: #ababab;
    line-height: 36px;
    position: relative;
    list-style: none;
    padding-left: 30px;
}

.fs-logo ul li i {
    font-size: 16px;
    color: #eeca42;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 8px;
}

.fs-logo .fs-social a {
    display: inline-block;
    height: 39px;
    width: 39px;
    border: 1px solid #3d3d3f;
    border-radius: 50%;
    text-align: center;
    line-height: 39px;
    color: #b3b7c8;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-right: 10px;
    margin-bottom: 10px;
}

.fs-logo .fs-social a:last-child {
    margin-right: 0;
}

.fs-logo .fs-social a:hover {
    background: #162330;
    border-color: #162330;
    color: #ffffff;
}

.fs-widget {
    margin-bottom: 30px;
    overflow: hidden;
}

.fs-widget h4 {
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 26px;
}

.fs-widget .fw-links {
    width: 42%;
    float: left;
}

.fs-widget .fw-links li {
    list-style: none;
}

.fs-widget .fw-links li a {
    font-size: 16px;
    color: #b5b8be;
    line-height: 36px;
    font-weight: 500;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.fs-widget .fw-links li a:hover {
    color: #c5141f;
}

.fs-widget .fw-item {
    margin-bottom: 18px;
}

.fs-widget .fw-item h5 {
    margin-bottom: 8px;
}

.fs-widget .fw-item h5 a {
    color: #b5b8be;
    font-weight: 500;
    line-height: 26px;
}

.fs-widget .fw-item ul li {
    list-style: none;
    display: inline-block;
    font-size: 14px;
    color: #ababab;
    margin-right: 20px;
    position: relative;
}

.fs-widget .fw-item ul li i {
    color: #162330;
}

.fs-widget .fw-item ul li:after {
    position: absolute;
    right: -12px;
    top: 0;
    content: "|";
}

.fs-widget .fw-item ul li:last-child {
    margin-right: 0;
}

.fs-widget .fw-item ul li:last-child:after {
    display: none;
}

.copyright-option {
    border-top: 1px solid #2d2e30;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 20px;
}

.copyright-option .co-text {
    font-size: 14px;
    color: #e0e0e0 !important;
    float: left;
    line-height: 24px;
}

.copyright-option .co-widget {
    text-align: right;
}

.copyright-option .co-widget ul {
    list-style: none;
}

.copyright-option .co-widget ul li {
    font-size: 14px;
    display: inline-block;
    margin-right: 45px;
}

.copyright-option .co-widget ul li a {
    color: #636363;
}

.copyright-option .co-widget ul li:last-child {
    margin-right: 0;
}


/* --------------------------------- Other Pages Styles --------------------------------- */


/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-section {
    height: 240px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.breadcrumb-section .bs-text h2 {
    font-size: 48px;
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
}


/*---------------------
  Schedule Section
-----------------------*/

.schedule-section {
    padding-top: 70px;
    padding-bottom: 70px;
}

.schedule-text .st-title {
    font-size: 22px;
    color: #ffffff;
    font-weight: 500;
    background: #162330;
    text-align: center;
    padding: 12px 0 14px;
    margin-bottom: 30px;
}

.schedule-text .st-table table {
    width: 100%;
    background-color: #fefefe;
}

.schedule-text .st-table table tbody tr {
    border-bottom: 1px solid #e5e5e5;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.schedule-text .st-table table tbody tr:last-child {
    border: none;
}

.schedule-text .st-table table tbody tr:hover {
    border-radius: 30px;
    /* box-shadow: inset 4px 4px 4px 2px #817f7ff7, inset -3px -5px 12px 0px #fffffff7; */
}

.schedule-text .st-table table tbody tr td {
    width: 150px;
    padding: 16px 0;
}

.schedule-text .st-table table tbody tr td img {
    height: 30px;
    width: 50px;
}

.schedule-text .st-table table tbody tr td h4 {
    line-height: 30px;
    color: #151618;
    font-weight: 500;
}

.schedule-text .st-table table tbody tr td.left-team {
    padding-left: 20px;
}

.schedule-text .st-table table tbody tr td.left-team img {
    float: left;
    margin-right: 10px;
}

.schedule-text .st-table table tbody tr td.left-team h4 {
    overflow: hidden;
}

.schedule-text .st-table table tbody tr td.right-team {
    text-align: right;
    padding-right: 20px;
}

.schedule-text .st-table table tbody tr td.right-team img {
    float: right;
    margin-left: 10px;
}

.schedule-text .st-table table tbody tr td.right-team h4 {
    float: right;
}

.schedule-text .st-table table tbody tr td.st-option {
    text-align: center;
}

.schedule-text .st-table table tbody tr td.st-option h4 {
    color: #162330;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 14px;
    margin-bottom: 12px;
}

.schedule-text .st-table table tbody tr td.st-option .so-text {
    font-size: 14px;
    color: #636363;
}

.schedule-sidebar .ss-widget {
    margin-bottom: 55px;
}

.schedule-sidebar .ss-widget.other-sport {
    margin-bottom: 0;
}

.schedule-sidebar .ss-widget ul li {
    list-style: none;
    position: relative;
    padding-left: 22px;
}

.schedule-sidebar .ss-widget ul li a {
    font-size: 16px;
    color: #636363;
    line-height: 30px;
}

.schedule-sidebar .ss-widget ul li:before {
    position: absolute;
    left: 0;
    top: 11px;
    height: 8px;
    width: 8px;
    background: #636363;
    content: "";
    border-radius: 50%;
}

.schedule-sidebar .ss-league {
    margin-bottom: 65px;
}

.schedule-sidebar .ss-league .sl-item {
    margin-bottom: 25px;
    display: block;
    overflow: hidden;
}

.schedule-sidebar .ss-league .sl-item img {
    height: 22px;
    width: 36px;
    float: left;
    margin-right: 10px;
}

.schedule-sidebar .ss-league .sl-item span {
    font-size: 14px;
    color: #2d2d2d;
    font-weight: 500;
    overflow: hidden;
    line-height: 22px;
    display: inline-block;
}


/*---------------------
  Blog Section
-----------------------*/

.blog-section {
    padding-top: 70px;
    padding-bottom: 70px;
}

.large-blog {
    height: 396px;
    position: relative;
    margin-bottom: 50px;
    width: 595px;
}

.large-blog .bi-tag {
    font-size: 12px;
    color: #ffffff;
    background: #162330;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    padding: 4px 15px;
    position: absolute;
    left: 20px;
    top: 20px;
}

.large-blog .bi-text {
    position: absolute;
    left: 0;
    bottom: 34px;
    width: 100%;
    padding: 0 30px;
}

.large-blog .bi-text h3 {
    margin-bottom: 15px;
}

.large-blog .bi-text h3 a {
    color: #ffffff;
    line-height: 38px;
    font-weight: 500;
}

.large-blog .bi-text ul li {
    list-style: none;
    display: inline-block;
    font-size: 13px;
    color: #ffffff;
    margin-right: 20px;
    position: relative;
}

.large-blog .bi-text ul li:after {
    position: absolute;
    right: -12px;
    top: 0;
    content: "|";
}

.large-blog .bi-text ul li:last-child {
    margin-right: 0;
}

.large-blog .bi-text ul li:last-child:after {
    display: none;
}

.blog-items .single-item {
    overflow: hidden;
    padding-bottom: 0px;
    border-bottom: 0px solid #e5e5e5;
    margin-bottom: 30px;
}

.blog-items .single-item:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.blog-items .single-item .bi-pic {
    float: left;
    margin-right: 30px;
}

.blog-items .single-item .bi-pic img {
    border-radius: 2px;
    min-width: 100%;
    height: 159px;
}

.blog-items .single-item .bi-text {
    overflow: hidden;
    padding-top: 10px;
}

.blog-items .single-item .bi-text h4 {
    margin-bottom: 12px;
}

.blog-items .single-item .bi-text h4 a {
    color: #151618;
    line-height: 32px;
    font-weight: 500;
}

.blog-items .single-item .bi-text ul {
    margin: 10px;
}

.blog-items .single-item .bi-text ul li {
    list-style: none;
    display: inline-block;
    font-size: 13px;
    color: #ababab;
    margin-right: 20px;
    position: relative;
}

.blog-items .single-item .bi-text ul li i {
    color: #162330;
}

.blog-items .single-item .bi-text ul li:after {
    position: absolute;
    right: -12px;
    top: 0;
    content: "|";
}

.blog-items .single-item .bi-text ul li:last-child {
    margin-right: 0;
}

.blog-items .single-item .bi-text ul li:last-child:after {
    display: none;
}

.blog-items .single-item .bi-text p {
    font-size: 14px;
    color: #636363;
    margin: 10px;
}

.more-blog {
    margin-top: 10px;
}

.flotright {
    float: right;
}

.floatleft {
    float: left;
}

.more-blog a {
    display: inline-block;
    font-size: 14px;
    color: #636363;
    border: 2px solid #333;
    border-radius: 2px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 22px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-right: 16px;
}

.more-blog a:hover {
    /* background: #fff; */
    color: #ffffff;
    border-color: #ffff;
}

.blog-sidebar .bs-categories {
    margin-bottom: 60px;
}

.blog-sidebar .bs-categories ul li {
    list-style: none;
    position: relative;
    padding-left: 22px;
}

.blog-sidebar .bs-categories ul li a {
    font-size: 16px;
    color: #151618;
    line-height: 30px;
}

.blog-sidebar .bs-categories ul li:before {
    position: absolute;
    left: 0;
    top: 11px;
    height: 8px;
    width: 8px;
    background: #636363;
    content: "";
    border-radius: 50%;
}

.blog-sidebar .bs-recent {
    margin-bottom: 65px;
}

.blog-sidebar .bs-popular-tag .tags a {
    display: inline-block;
    font-size: 14px;
    color: #636363;
    font-weight: 500;
    text-transform: uppercase;
    background: #f2f2f2;
    padding: 4px 16px;
    margin-right: 6px;
    margin-bottom: 10px;
}


/*---------------------
  Blog Hero
-----------------------*/

.blog-hero-section {
    height: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-hero-section .bh-text h2 {
    font-size: 48px;
    color: #ffffff;
    font-weight: 500;
    line-height: 60px;
    margin-bottom: 18px;
}

.blog-hero-section .bh-text ul li {
    list-style: none;
    display: inline-block;
    font-size: 13px;
    color: #ababab;
    margin-right: 20px;
    position: relative;
}

.blog-hero-section .bh-text ul li i {
    color: #162330;
}

.blog-hero-section .bh-text ul li:after {
    position: absolute;
    right: -12px;
    top: 0;
    content: "|";
}

.blog-hero-section .bh-text ul li:last-child {
    margin-right: 0;
}

.blog-hero-section .bh-text ul li:last-child:after {
    display: none;
}


/*---------------------
  Blog Details
-----------------------*/

.blog-details-section {
    padding-top: 70px;
    padding-bottom: 70px;
}

.left-blog-pad {
    padding-right: 60px;
}

.bd-text .bd-title {
    margin-bottom: 45px;
}

.bd-text .bd-pic {
    margin-bottom: 16px;
}

.bd-text .bd-pic .row {
    margin: 0 -10px;
}

.bd-text .bd-pic .col-md-4,
.bd-text .bd-pic .col-sm-6,
.bd-text .bd-pic .col-md-8 {
    padding: 0 10px;
}

.bd-text .bd-pic img {
    min-width: 100%;
    margin-bottom: 20px;
}

.bd-text .bd-more-title p {
    margin-bottom: 30px;
}

.bd-text .bd-tags {
    padding-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 45px;
    margin-bottom: 65px;
}

.bd-text .bd-tags a {
    display: inline-block;
    color: #636363;
    background: #f2f2f2;
    border-radius: 2px;
    padding: 4px 24px;
    text-transform: uppercase;
    margin-right: 6px;
    margin-bottom: 6px;
}

.bd-text .comment-option h4 {
    color: #151618;
    font-weight: 500;
    margin-bottom: 35px;
}

.bd-text .comment-option .single-comment-item {
    margin-bottom: 30px;
}

.bd-text .comment-option .single-comment-item.reply-comment {
    padding-left: 131px;
}

.bd-text .comment-option .single-comment-item.reply-comment .sc-author {
    margin-right: 30px;
}

.bd-text .comment-option .single-comment-item.first-comment .sc-text {
    position: relative;
}

.bd-text .comment-option .single-comment-item.first-comment .sc-text:before {
    position: absolute;
    left: 0;
    top: 8px;
    height: 260px;
    width: 1px;
    background: #e9e9e9;
    content: "";
}

.bd-text .comment-option .single-comment-item.second-comment .sc-text {
    position: relative;
}

.bd-text .comment-option .single-comment-item.second-comment .sc-text:before {
    position: absolute;
    left: 0;
    top: 8px;
    height: 100px;
    width: 1px;
    background: #e9e9e9;
    content: "";
}

.bd-text .comment-option .single-comment-item .sc-author {
    float: left;
    margin-right: 30px;
}

.bd-text .comment-option .single-comment-item .sc-author img {
    height: 70px;
    width: 70px;
    border-radius: 50%;
}

.bd-text .comment-option .single-comment-item .sc-text {
    display: table;
    padding-left: 30px;
}

.bd-text .comment-option .single-comment-item .sc-text span {
    font-size: 12px;
    color: #162330;
}

.bd-text .comment-option .single-comment-item .sc-text h5 {
    color: #151618;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 14px;
}

.bd-text .comment-option .single-comment-item .sc-text p {
    font-size: 14px;
    line-height: 22px;
}

.bd-text .comment-option .single-comment-item .sc-text .comment-btn {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    color: #162330;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    padding: 4px 26px;
    font-weight: 500;
    letter-spacing: 1px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 10px;
}

.bd-text .comment-option .single-comment-item .sc-text .comment-btn.like-btn {
    margin-right: 10px;
}

.bd-text .comment-option .single-comment-item .sc-text .comment-btn:hover {
    background: #162330;
    border-color: #162330;
    color: #ffffff;
}

.bd-text .comment-form h4 {
    color: #151618;
    font-weight: 500;
    margin-bottom: 35px;
}

.bd-text .comment-form form input {
    height: 46px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    width: 100%;
    padding-left: 20px;
    font-size: 14px;
    color: #ababab;
    margin-bottom: 30px;
    padding-right: 5px;
}

.bd-text .comment-form form input::-webkit-input-placeholder {
    color: #ababab;
}

.bd-text .comment-form form input::-moz-placeholder {
    color: #ababab;
}

.bd-text .comment-form form input:-ms-input-placeholder {
    color: #ababab;
}

.bd-text .comment-form form input::-ms-input-placeholder {
    color: #ababab;
}

.bd-text .comment-form form input::placeholder {
    color: #ababab;
}

.bd-text .comment-form form textarea {
    height: 120px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    width: 100%;
    padding-left: 20px;
    padding-top: 12px;
    font-size: 14px;
    color: #ababab;
    resize: none;
    margin-bottom: 32px;
    padding-right: 5px;
}

.bd-text .comment-form form textarea::-webkit-input-placeholder {
    color: #ababab;
}

.bd-text .comment-form form textarea::-moz-placeholder {
    color: #ababab;
}

.bd-text .comment-form form textarea:-ms-input-placeholder {
    color: #ababab;
}

.bd-text .comment-form form textarea::-ms-input-placeholder {
    color: #ababab;
}

.bd-text .comment-form form textarea::placeholder {
    color: #ababab;
}

.bd-text .comment-form form button {
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    background: #162330;
    border: none;
    padding: 12px 34px 10px 40px;
    border-radius: 2px;
}


/*---------------------
  Map
-----------------------*/

.map {
    height: 500px;
}

.map iframe {
    width: 100%;
}


/*---------------------
  Contact Section
-----------------------*/

.contact-section {
    padding-bottom: 70px;
    padding-top: 90px;
}

.contact-form h2 {
    color: #151618;
    font-weight: 500;
    margin-bottom: 38px;
}

.contact-form form .group-in {
    margin-bottom: 25px;
}

.contact-form form .group-in label {
    font-size: 16px;
    color: #151618;
}

.contact-form form .group-in input {
    width: 100%;
    height: 46px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 14px;
    color: #151618;
    padding-left: 20px;
    padding-right: 5px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.contact-form form .group-in input:focus {
    border-color: #162330;
}

.contact-form form .group-in textarea {
    width: 100%;
    height: 140px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 14px;
    color: #151618;
    padding-left: 20px;
    padding-right: 5px;
    resize: none;
    padding-top: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 8px;
}

.contact-form form .group-in textarea:focus {
    border-color: #162330;
}

.contact-form form button {
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    background: #162330;
    border: none;
    padding: 12px 34px 10px 40px;
    letter-spacing: 1px;
}

.contact-info h2 {
    color: #151618;
    font-weight: 500;
    margin-bottom: 38px;
}

.contact-info p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
}

.contact-info .ci-address {
    margin-bottom: 20px;
}

.contact-info .ci-address h5 {
    color: #151618;
    font-weight: 500;
    margin-bottom: 12px;
}

.contact-info .ci-address ul li {
    list-style: none;
    font-size: 14px;
    color: #636363;
    line-height: 30px;
}


/*---------------------
  Club Section
-----------------------*/

.club-section {
    padding-top: 80px;
    padding-bottom: 70px;
}

.club-content .cc-pic {
    text-align: center;
}

.club-content .cc-text .ct-title {
    margin-bottom: 45px;
}

.club-content .cc-text .ct-title h3 {
    font-size: 28px;
    color: #151618;
    font-weight: 500;
    margin-bottom: 30px;
}

.club-content .cc-text .ct-widget ul li {
    list-style: none;
    display: inline-block;
    width: 30%;
    float: left;
    margin-bottom: 12px;
}

.club-content .cc-text .ct-widget ul li h5 {
    color: #151618;
    font-weight: 500;
    margin-bottom: 8px;
}

.club-content .cc-text .ct-widget ul li .cw-social a {
    display: inline-block;
    color: #636363;
    margin-right: 20px;
}

.club-content .cc-text .ct-widget ul li .cw-social a:last-child {
    margin-right: 0;
}

.club-tab-list {
    margin-top: 30px;
}

.club-tab-list .nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 48px;
    border-bottom: 1px solid #e5e5e5;
}

.club-tab-list .nav .nav-item {
    margin-right: 45px;
}

.club-tab-list .nav .nav-item:last-child {
    margin-right: 0px;
}

.club-tab-list .nav .nav-item .nav-link {
    color: #ababab;
    font-weight: 500;
    font-size: 18px;
    background: transparent;
    border: none;
    padding: 0;
}

.club-tab-list .nav .nav-item .nav-link.active {
    color: #151618;
}

.club-tab-list .tab-content .club-tab-content .ct-item {
    overflow: hidden;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
}

.club-tab-list .tab-content .club-tab-content .ct-item .ci-text {
    float: left;
}

.club-tab-list .tab-content .club-tab-content .ct-item .ci-text img {
    height: 70px;
    width: 70px;
    float: left;
    margin-right: 20px;
}

.club-tab-list .tab-content .club-tab-content .ct-item .ci-text h5 {
    color: #151618;
    font-weight: 500;
    overflow: hidden;
    padding-top: 24px;
}

.club-tab-list .tab-content .club-tab-content .ct-item .ci-name {
    float: right;
    color: #636363;
    font-size: 18px;
    font-weight: 500;
    padding-top: 22px;
}


/*-------------------------------- Respinsive Media Styles --------------------------------*/

@media only screen and (min-width: 1301px) and (max-width: 1750px) {
    .trending-news-section .tn-title {
        width: 25%;
    }
    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .trending-news-section .tn-title {
        width: 22%;
    }
    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1270px;
    }
    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .title_match_upcoming {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 225px;
    }
    .upcoimg_team {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 225px;
    }
    .spandate {
        color: #333;
        /* margin-left: 155px !important; */
    }
}


/* Medium Device: 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header__nav .nav-menu .main-menu li {
        margin-right: 40px;
    }
    .ms-content .mc-table table tr td.left-team {
        padding-left: 15px;
    }
    .ms-content .mc-table table tr td.right-team {
        padding-right: 15px;
    }
    .club-content .cc-text .ct-widget ul li {
        width: 33.33%;
    }
    .trending-news-section .tn-title {
        width: 25%;
    }
    .left-blog-pad {
        padding-right: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .vote-option {
        margin-bottom: 30px;
    }
    .header__nav .nav-menu {
        display: none;
    }
    .header__top .ht-info {
        display: none;
    }
    .header__top .ht-links {
        display: none;
    }
    .header__nav .container {
        position: relative;
    }
    .offcanvas-menu-overlay {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 98;
        height: 100%;
        width: 100%;
        visibility: hidden;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .offcanvas-menu-overlay.active {
        visibility: visible;
    }
    .canvas-open {
        position: absolute;
        right: 40px;
        top: 25px;
        font-size: 22px;
        width: 30px;
        height: 30px;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 2px;
        line-height: 30px;
        text-align: center;
        z-index: 100;
        display: none;
        cursor: pointer;
    }
    .offcanvas-menu-wrapper {
        position: fixed;
        left: -300px;
        top: 0;
        width: 300px;
        z-index: 999;
        background: #ffffff;
        text-align: center;
        overflow-y: auto;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        padding: 70px 30px 30px 30px;
        display: block;
    }
    .mobilecanvasclose {
        color: #fff;
    }
    .offcanvas-menu-wrapper.show-offcanvas-menu-wrapper {
        left: 0;
        opacity: 1;
        visibility: visible;
    }
    .offcanvas-menu-wrapper .canvas-close {
        font-size: 22px;
        width: 30px;
        height: 30px;
        border: 1px solid #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    .offcanvas-menu-wrapper .header__top--canvas {
        margin-bottom: 20px;
    }
    .offcanvas-menu-wrapper .header__top--canvas .ht-info {
        margin-bottom: 10px;
    }
    .offcanvas-menu-wrapper .header__top--canvas .ht-info ul li {
        list-style: none;
        color: #636363;
        line-height: 28px;
    }
    .offcanvas-menu-wrapper .header__top--canvas .ht-info ul li a {
        display: block;
        color: #636363;
    }
    .offcanvas-menu-wrapper .header__top--canvas .ht-links a {
        font-size: 16px;
        color: #636363;
        margin-right: 10px;
    }
    .offcanvas-menu-wrapper .header__top--canvas .ht-links a:last-child {
        margin-right: 0;
    }
    .offcanvas-menu-wrapper .search-btn {
        margin-bottom: 10px;
        cursor: pointer;
    }
    .offcanvas-menu-wrapper .main-menu {
        display: none;
    }
    .offcanvas-menu-wrapper .slicknav_btn {
        display: none;
    }
    .offcanvas-menu-wrapper .slicknav_menu {
        padding: 0;
        background: #222;
        border-radius: 24px;
    }
    .offcanvas-menu-wrapper .slicknav_nav {
        display: block !important;
    }
    .offcanvas-menu-wrapper .slicknav_nav a:hover {
        border-radius: 0;
        background: #162330;
        color: #ffffff;
    }
    .offcanvas-menu-wrapper .slicknav_nav .slicknav_row:hover {
        border-radius: 0;
        background: #162330;
        color: #ffffff;
    }
    .offcanvas-menu-wrapper .slicknav_nav .slicknav_row,
    .offcanvas-menu-wrapper .slicknav_nav a {
        padding: 10px 10px;
        margin: 0;
    }
    .club-content .cc-pic {
        margin-bottom: 30px;
    }
    .schedule-sidebar {
        margin-top: 30px;
    }
    .blog-sidebar {
        margin-top: 40px;
    }
    .contact-info {
        padding-top: 40px;
    }
    .bottom-tabs {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        font-size: 13px;
        z-index: 99;
        display: block !important;
    }
    .bottom-tabs .bottom-image {
        width: 100%;
        position: absolute;
        top: -20px;
        bottom: 0;
        left: 0;
        right: 0;
        
    }
    .bottom-tabs .bottom-image svg {
        width: 100%;
        height: 115px;
    }
    .bottom-tabs .bottom-image svg path,
    .bottom-tabs .bottom-image svg circle {
        fill: #2c3134;
    }
    .bottom-tabs ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
    }
    .bottom-tabs ul li {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 60px;
    }
    .bottom-tabs ul li a {
        color: #000;
        cursor: pointer;
        font-size: 11px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-weight: 600;
        letter-spacing: 0.3px;
        width: 40px;
        height: 40px;
        background-color: #202427;
        border-radius: 50%;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        top: 2px;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
    }
    .bottom-tabs ul li a img {
        width: 40px;
        height: 40px;
        display: inline-block;
        margin: auto;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
    }
    .bottom-tabs ul li a svg {
        display: inline-block;
        margin: auto;
        width: 25px;
        height: 25px;
    }
    .bottom-tabs ul li a svg path,
    .bottom-tabs ul li a svg rect {
        stroke: #c5141f;
    }
    .bottom-tabs ul li a.blank-bg {
        background-color: transparent;
        width: auto;
        height: auto;
    }
    .bottom-tabs ul li a .badge {
        background-color: #eeca42;
        color: #292d32;
        font-size: 9px;
        font-weight: 700;
        min-width: 16px;
        height: 16px;
        position: absolute;
        margin-left: 0;
        text-align: center;
        top: -4px;
        right: -4px;
        border: 1px solid #000;
        border-radius: 50%;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .bottom-tabs ul li a:hover,
    .bottom-tabs ul li a.active {
        background-color: #fefefe;
        -webkit-box-shadow: 1px 1px 2px rgba(255, 255, 73, 0.3), -1px -1px 2px rgba(182, 164, 41, 0.5), inset -3px 3px 6px rgba(182, 164, 41, 0.2), inset 3px -3px 6px rgba(182, 164, 41, 0.2), inset -3px -3px 6px rgba(243, 236, 236, 0.9), inset 3px 3px 8px rgba(243, 236, 236, 0.9);
        box-shadow: 1px 1px 2px rgba(255, 255, 73, 0.3), -1px -1px 2px rgba(182, 164, 41, 0.5), inset -3px 3px 6px rgba(182, 164, 41, 0.2), inset 3px -3px 6px rgba(182, 164, 41, 0.2), inset -3px -3px 6px rgba(243, 236, 236, 0.9), inset 3px 3px 8px rgba(243, 236, 236, 0.9);
        width: 45px;
        height: 45px;
    }
    .bottom-tabs ul li a:hover img,
    .bottom-tabs ul li a.active img {
        width: 45px;
        height: 45px;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .bottom-tabs ul li a:hover svg path,
    .bottom-tabs ul li a:hover svg circle,
    .bottom-tabs ul li a:hover svg text,
    .bottom-tabs ul li a:hover svg rect,
    .bottom-tabs ul li a.active svg path,
    .bottom-tabs ul li a.active svg circle,
    .bottom-tabs ul li a.active svg text,
    .bottom-tabs ul li a.active svg rect {
        stroke: #c5141f;
    }
    .bottom-tabs ul li a .username {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .bottom-tabs ul li a .fa-times {
        font-size: 18px;
        color: #000;
    }
    .bottom-tabs ul li.big {
        position: absolute;
        height: 70px;
        width: 20%;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 10px;
    }
    .bottom-tabs ul li.big::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        z-index: -1;
        margin: auto;
        background-color: #c5141f;
        -webkit-box-shadow: 1px 1px 2px rgba(255, 255, 73, 0.3), -1px -1px 2px rgba(182, 164, 41, 0.5), inset -3px 3px 6px rgba(182, 164, 41, 0.2), inset 3px -3px 6px rgba(182, 164, 41, 0.2), inset -3px -3px 6px rgba(197, 20, 31, 1), inset 3px 3px 8px rgba(197, 20, 31, 1);
        box-shadow: 1px 1px 2px rgba(255, 255, 73, 0.3), -1px -1px 2px rgba(182, 164, 41, 0.5), inset -3px 3px 6px rgba(182, 164, 41, 0.2), inset 3px -3px 6px rgba(182, 164, 41, 0.2), inset -3px -3px 6px rgba(197, 20, 31, 1), inset 3px 3px 8px rgba(197, 20, 31, 1);
    }
    .bottom-tabs ul li.big a {
        position: relative;
        color: #000;
        top: -13px;
        background-color: transparent;
        width: auto;
        height: auto;
    }
    .bottom-tabs ul li.big a svg {
        margin-bottom: 0;
        width: 40px;
        height: 40px;
        margin: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
    }
    .bottom-tabs ul li.big a img {
        width: 32px;
    }
    .bottom-tabs ul li.big a:hover,
    .bottom-tabs ul li.big a.active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .bottom-tabs ul li.big a.bowler svg {
        fill: #fff;
    }
    .bottom-tabs ul li.big:hover,
    .bottom-tabs ul li.big.active {
        background-color: transparent;
    }
    .bottom-tabs ul li.big .menu-item {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 85%;
        line-height: 5px;
        top: 65%;
        margin: -50px 0 0 -50px;
        border-radius: 50%;
        background-color: #fff;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
        -webkit-transition: -webkit-transform 500ms;
        transition: -webkit-transform 500ms;
        transition: transform 500ms;
        transition: transform 500ms, -webkit-transform 500ms;
        z-index: -2;
        -webkit-box-shadow: var(--bottom-tab-menuitem-shadow);
        box-shadow: var(--bottom-tab-menuitem-shadow);
    }
    .bottom-tabs ul li.big .menu-item a {
        color: #000;
        position: relative;
        top: 12px;
        left: 0;
        text-decoration: none;
        font-size: 10px;
        line-height: 1.3;
    }
    .bottom-tabs ul li.big .menu-item a svg {
        width: 26px;
        height: 26px;
        margin: auto;
    }
    .bottom-tabs ul li.all-items .items:nth-child(5) {
        -webkit-transform: rotate(60deg) translateY(-90px) rotate(300deg);
        transform: rotate(60deg) translateY(-90px) rotate(300deg);
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }
    .bottom-tabs ul li.all-items .items:nth-child(4) {
        -webkit-transform: rotate(20deg) translateY(-95px) rotate(-20deg);
        transform: rotate(20deg) translateY(-95px) rotate(-20deg);
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
    }
    .bottom-tabs ul li.all-items .items:nth-child(2) {
        -webkit-transform: rotate(-20deg) translateY(-95px) rotate(20deg);
        transform: rotate(-20deg) translateY(-95px) rotate(20deg);
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }
    .bottom-tabs ul li.all-items .items:nth-child(3) {
        -webkit-transform: rotate(-60deg) translateY(-90px) rotate(60deg);
        transform: rotate(-60deg) translateY(-90px) rotate(60deg);
        -webkit-transition-delay: 0.3s;
        transition-delay: 0.3s;
    }
    .bottom-tabs ul li.all-items .bottom-circle {
        z-index: -3;
        position: absolute;
        top: 14%;
        left: 53%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 260px;
        width: 260px;
        border-radius: 160px 160px 0 0;
        background-color: #fff;
        -webkit-box-shadow: var(--bottom-tab-circle-shadow);
        box-shadow: var(--bottom-tab-circle-shadow);
    }
    .bottom-tabs ul li .fill-icon svg path {
        fill: #8d8c8c;
    }
    .bottom-tabs ul.dropdown-content {
        max-width: 65%;
        right: -5px;
        left: auto !important;
        top: auto !important;
        bottom: 0px;
        padding: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: #fff;
        -webkit-box-shadow: none;
        box-shadow: none;
        display: none;
        width: 100% !important;
        height: 100vh !important;
        z-index: 999;
        opacity: 1;
        visibility: visible;
    }
    .bottom-tabs ul.dropdown-content li {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        height: 45px;
        min-height: 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding-left: 5px;
    }
    .bottom-tabs ul.dropdown-content li:first-child {
        margin-top: 15px;
    }
    .bottom-tabs ul.dropdown-content li:last-child {
        bottom: 30px;
        position: absolute;
    }
    .bottom-tabs ul.dropdown-content li a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 600;
        line-height: 2.2;
        color: #000;
        -webkit-box-orient: initial;
        -webkit-box-direction: initial;
        -ms-flex-direction: initial;
        flex-direction: initial;
        background-color: transparent;
        width: auto;
    }
    .bottom-tabs ul.dropdown-content li a svg {
        display: inline-block;
        margin: 0 8px 0 0;
        width: 22px;
        height: 22px;
    }
    .bottom-tabs ul.dropdown-content li a i {
        font-size: 14px;
        margin-right: 5px;
    }
    .bottom-tabs ul.dropdown-content li a:hover,
    .bottom-tabs ul.dropdown-content li a.active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .bottom-tabs ul.dropdown-content li a.close-btn {
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        bottom: 0px;
        position: inherit;
    }
    .bottom-tabs ul.dropdown-content li a.close-btn .fa-times {
        cursor: pointer;
        position: absolute;
        bottom: 50px;
        color: #fff;
        font-size: 20px;
        margin-right: 0;
        padding: 0px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background-color: #eeca42;
        text-align: center;
        border-radius: 50%;
        -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px, rgba(0, 0, 0, 0.23) 0px 2px 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px, rgba(0, 0, 0, 0.23) 0px 2px 5px;
    }
    .bottom-tabs ul.dropdown-content li a.close-btn:hover,
    .bottom-tabs ul.dropdown-content li a.close-btn:focus,
    .bottom-tabs ul.dropdown-content li a.close-btn.active {
        background-color: transparent;
    }
    .bottom-tabs ul.dropdown-content li a.close-btn:hover .fa-times,
    .bottom-tabs ul.dropdown-content li a.close-btn:focus .fa-times,
    .bottom-tabs ul.dropdown-content li a.close-btn.active .fa-times {
        background-color: #fff;
        color: #eeca42;
        -webkit-transition: all ease-in .3s;
        transition: all ease-in .3s;
    }
    .bottom-tabs ul.dw-open {
        right: -5px !important;
        -webkit-transform: translate3d(0, 0, 0px) !important;
        transform: translate3d(0, 0, 0px) !important;
    }
}


/* Tablet Device: 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright-option .co-widget ul li {
        margin-right: 10px;
    }
    .trending-news-section .tn-title {
        padding-right: 50px;
    }
    .trending-news-section .news-slider .nt-item {
        padding-left: 225px;
    }
    .trending-news-section .news-slider.owl-carousel .owl-nav {
        right: -35px;
    }
    .left-blog-pad {
        padding-right: 15px;
    }
    .hero-section{
        background-size: 100% !important;
        background-repeat: no-repeat;
        background-position: top !important;
        height: 375px !important;
    }
    .bottom-tabs{
        .bottom-image{
            background-image: radial-gradient(circle at 50% 6px,transparent 115px, #2c3134 57px);
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
    }
}


/* Large Mobile: 480px */

@media only screen and (max-width: 767px) {
    .ms-content .mc-table table tr td.left-team {
        padding-left: 15px;
    }
    .ms-content .mc-table table tr td.right-team {
        padding-right: 15px;
    }
    .section-title.latest-title h3 {
        float: none;
    }
    .section-title.latest-title ul {
        text-align: left;
        padding-top: 10px;
    }
    .section-title.latest-title ul li {
        margin-bottom: 5px;
    }
    .club-content .cc-text .ct-widget ul li {
        width: 33.33%;
    }
    .club-tab-list .nav .nav-item {
        margin-right: 10px;
    }
    .copyright-option .co-text {
        margin-bottom: 5px;
    }
    .copyright-option .co-widget {
        text-align: center;
    }
    .copyright-option .co-text {
        float: none;
        text-align: center;
    }
    .schedule-text .st-table table tbody tr td.left-team img {
        float: none;
        margin-right: 0;
    }
    .schedule-text .st-table table tbody tr td.left-team h4 {
        overflow: visible;
        display: block;
        margin-top: 10px;
    }
    .schedule-text .st-table table tbody tr td.right-team img {
        float: none;
        margin-left: 0;
    }
    .schedule-text .st-table table tbody tr td.right-team h4 {
        float: none;
        margin-top: 10px;
    }
    .blog-items .single-item .bi-pic {
        float: none;
        margin-right: 0;
    }
    .blog-items .single-item .bi-text {
        padding-top: 20px;
    }
    .trending-news-section .tn-title {
        font-size: 17px;
        padding-right: 16px;
        padding-top: 17px;
    }
    .hs-slider.owl-carousel .owl-nav {
        right: 60px;
    }
    .trending-news-section .tn-title {
        display: none;
    }
    .trending-news-section .news-slider.owl-carousel .owl-nav {
        display: none;
    }
    .trending-news-section .news-slider .nt-item {
        padding-left: 0;
    }
    .search-model-form {
        width: 100%;
    }
    .search-model-form input {
        width: 100%;
    }
    .left-blog-pad {
        padding-right: 15px;
    }
    .section {
        margin: 0 10px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
        --swiper-navigation-size: 20px !important;
    }
    .swiper-button-next {
        right: -25px !important;
        top: 40%;
    }
    .swiper-button-prev {
        left: -25px !important;
        top: 40%;
    }
    .vs-image-content {
        margin-left: -15px !important;
        margin-top: -15px;
    }
    .news-item .ni-text {
        overflow: visible;
        /* padding-top: 200px; */
    }
    .margin12 {
        margin: 12px;
    }
    .news-item .ni-text h5 {
        margin-bottom: 0px;
        margin-top: 19px;
    }
    .news-item .ni-pic img {
        margin: 10px 0px 0px 25px;
        border-radius: 30px;
        height: auto;
        width: 300px;
    }
    .mobileNewimage {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .large-blog {
        height: 396px;
        position: relative;
        margin-bottom: 50px;
        width: 295px;
    }
    #loading {
        margin-left: 180px !important;
    }
    .spandate {
        color: #333;
        margin-left: 74px !important;
    }
    .mobi-newsDatetime {
        margin-left: 20px !important;
        margin-bottom: 6px;
        margin-top: -10px;
    }
    .hero-section{
        background-size: 100% !important;
        background-repeat: no-repeat;
    }
    .modaldilog_custompopup{
        width: 70%;
        margin: auto;
        height: 100%;
    
    }
}


/* Small Mobile: 320px */

@media only screen and (max-width: 479px) {
    .club-content .cc-text .ct-widget ul li {
        width: 100%;
    }
    .club-tab-list .nav .nav-item .nav-link {
        margin-bottom: 10px;
    }
    .club-tab-list .tab-content .club-tab-content .ct-item .ci-text {
        float: none;
        overflow: hidden;
    }
    .club-tab-list .tab-content .club-tab-content .ct-item .ci-name {
        float: none;
    }
    .schedule-text .st-table {
        overflow-x: none;
    }
    .more-blog a {
        margin-bottom: 10px;
    }
    .bd-text .comment-option .single-comment-item.reply-comment .sc-text {
        display: table;
        padding-left: 0;
        padding-top: 10px;
    }
    .hs-slider.owl-carousel .owl-nav {
        right: 20px;
    }
    .search-model-form input {
        font-size: 24px;
    }
    .ms-content .mc-table table tr td h6 {
        font-size: 12px;
    }
    .video-item {
        height: 300px;
    }
    .copyright-option .co-widget ul li {
        font-size: 12px;
        margin-right: 10px;
    }
    .bd-text .comment-option .single-comment-item .sc-author {
        float: none;
    }
    .bd-text .comment-option .single-comment-item .sc-text {
        padding-left: 0;
    }
    .bd-text .comment-option .single-comment-item.first-comment .sc-text::before,
    .bd-text .comment-option .single-comment-item.second-comment .sc-text:before {
        display: none;
    }
    .bd-text .comment-option .single-comment-item.reply-comment {
        padding-left: 5%;
    }
    .breadcrumb-section .bs-text h2 {
        font-size: 36px;
    }
    .blog-hero-section .bh-text h2 {
        font-size: 28px;
        line-height: normal;
    }
    .blog-hero-section {
        height: 410px;
    }
    .hs-item .hs-text h2 {
        font-size: 25px;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 0px;
    }
    .hero-section {
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .breadcrumb-section .bs-text h2 {
        font-size: 48px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
    }
    .news-item .ni-text {
        overflow: visible;
        /* padding-top: 200px; */
    }
    .margin12 {
        margin: 12px;
    }
    .news-item .ni-text h5 {
        margin-bottom: 0px;
        margin-top: -10px;
    }

    .spandatecompletmatches {
        color: #333;
        margin-left: 55px !important;
    }
    .commentryDatetime{
        float: left;
        margin-left: 10px !important;
        color: #333333;
        font-weight: 650;
    }

    .mobilenews{
        /* text-align: center; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 46vh;
  margin-left: 51px;
    }

    .ptag {
        margin: 10px !important;
    }

    .blog-items .single-item .bi-pic img {
        border-radius: 2px;
        min-width: 100%;
        height: auto;
    }

    
.fs-logo .logo {
    margin-bottom: 30px;
    margin-left: 5px;
}
}
@media (min-width: 320px) and (max-width: 359px){
    .hero-section{
        height: 152px !important;
    }
}
@media (min-width: 360px) and (max-width: 399px){
    .hero-section{
        height: 170px !important;
    }
}
@media (min-width: 400px) and (max-width: 479px){
    .hero-section{
        height: 190px !important;
    }
}
@media (min-width: 480px) and (max-width: 579px){
    .hero-section{
        height: 220px !important;
    }
}
@media (min-width: 580px) and (max-width: 767px){
    .hero-section{
        height: 350px !important;
    }
}

.title_match {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    margin: 7px;
}

.st-table {
    /* background-color: #c7cbd1; */
}

.dot {
    height: 12px;
    width: 12px;
    background-color: #18a920;
    border-radius: 50%;
    display: inline-block;
}

.bottomBorder {
    border-bottom: 1px solid #bfbcbcd1;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.gallery {
    background: #EEE;
}

.gallery-cell {
    width: 66%;
    height: 200px;
    margin-right: 10px;
    background: rgb(243, 247, 243);
}


/* cell number */

.gallery-cell:before {
    display: block;
    text-align: center;
    content: counter(gallery-cell);
    line-height: 200px;
    font-size: 80px;
}

.container-swiper {
    max-width: 1280px;
    margin: 0 auto;
    height: 50%;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: -webkit-flex; */
    /* display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.home_tables tr:last-child td:first-child {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
}

.home_tables tr:last-child td:last-child {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}

.home_tables {
    border-bottom-right-radius: 31px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    box-shadow: 5px 6px 12px 1px #000, -4px -3px 15px 2px #524c4cf7;
}

.side-canvas {
    box-shadow: 5px 6px 12px 1px #000, -4px -3px 15px 2px #524c4cf7;
    border-radius: 25px;
}

.sidemenuDiv {
    background-color: #333;
}

#button {
    display: inline-block;
    background-color: #c5141f;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 30px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    margin-bottom: 35px;
}

#button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #fff;
}

#button:hover {
    cursor: pointer;
    background-color: #2a2b2d;
}

#button:active {
    background-color: #2a2b2d;
}

#button.show {
    opacity: 1;
    visibility: visible;
}


/* Styles for the content section */

.content {
    width: 77%;
    margin: 50px auto;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 17px;
    color: #6c767a;
    line-height: 1.9;
}

@media (min-width: 500px) {
    .content {
        width: 43%;
    }
    #button {
        margin: 30px;
    }
}

.content h1 {
    margin-bottom: -10px;
    color: #03a9f4;
    line-height: 1.5;
}

.content h3 {
    font-style: italic;
    color: #96a2a7;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}

table {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    /* box-shadow: 4px 4px 4px 2px #817f7ff7, -3px -5px 12px 0px #fffffff7; */
}

@-moz-document url-prefix() {
    table {
        /* box-shadow: 0px 0px 0px 0px #fffffff7 !important; */
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        /* box-shadow: 4px 4px 4px 2px #817f7ff7, -3px -5px 12px 0px #fffffff7; */
    }
    .home_tables {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        /* box-shadow: 5px 6px 12px 1px #000, -4px -3px 15px 2px #524c4cf7; */
    }
    .schedule-text .st-table table tbody tr:hover {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        /* box-shadow: inset 4px 4px 4px 2px #817f7ff7, inset -3px -5px 12px 0px #fffffff7; */
    }
}

.arrow {
    border: solid #fff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 10px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.title_news {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1050px;
}

.upcomingtblp10 {
    padding: 10px 10px 10px 10px;
}

.footerGoogleplay {
    padding-top: 15px;
}

.footerLogo {
    width: 200px;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    /* border-radius: 30px;
    box-shadow: inset 5px 6px 12px 1px #000, inset -4px -3px 15px 2px #524c4cf7; */
}

.headerimg {
    border-radius: 54px;
    box-shadow: inset 5px 6px 12px 1px #000, inset -4px -3px 15px 2px #524c4cf7;
    text-align: center;
}

.homeimg {
    width: 80px;
    height: 80px;
}

.card {
    /* padding: 1.5em .5em .5em; */
    border-radius: 2em;
    text-align: center;
    /* box-shadow: 5px 5px 5px 0px #000, -2px -2px 2px 1px #8d8989; */
    background-color: #fafafa;
    margin: 10px;
}

.card.upcomingCard {
    /* padding: 1.5em .5em .5em; */
    border-radius: 20px;
    text-align: center;
    /* box-shadow: 5px 5px 5px 0px #000, -2px -2px 2px 1px #8d8989; */
    background-color: #fafafa;
    margin: 10px;
}

.card-body {
    padding: 0.5rem;
}

.liveSetboxshadow {
    box-shadow: inset 5px 6px 12px 1px #000, inset -4px -3px 15px 2px #524c4cf7;
    border-radius: 40px;
}

.section {
    position: relative;
    z-index: 1;
    right: 0;
    bottom: 0px;
    margin: 0 40px;
}

.section .sports-slider {
    overflow: hidden;
}

.swiper-button-next {
    right: -40px;
}

.swiper-button-prev {
    left: -40px;
}

.swiper-button-next,
.swiper-button-prev {
    height: 25px;
    --swiper-navigation-size: 30px;
    color: #333;
    width: 25px;
    border-radius: 8px;
    font-weight: 700;
}

.swiper-wrapper {
    margin-bottom: 12px !important;
}

.vs-image-content {
    margin-left: -11px;
    margin-top: -15px;
}

.vs-image-content-pages {
    margin-left: 0px;
    margin-top: 15px;
}

.teamNameHome {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    margin: 7px;
}

.margintop18 {
    margin-top: 18px;
}

.margintop25 {
    margin-top: 25px;
}

.boxshadowlight {
    border-radius: 20px;
    background-color: #fff;
    /* box-shadow: 4px 4px 4px 2px #817f7ff7, -3px -5px 12px 0 #fffffff7; */
}

.margin12 {
    margin: 12px;
}

.iframeboxshadow {
    border-radius: 30px;
    box-shadow: 4px 4px 4px 2px #817f7ff7, -3px -5px 12px 0 #fffffff7;
}

.bfcolorGrey {
    background-color: #fefefe;
}

.upcoimg_team {
    text-overflow: ellipsis;
    max-width: 160px;
}

.darkColor {
    color: #333 !important;
}

.pos {
    position: relative;
    margin: 0 auto;
}

.down-arrow {
    height: 60px;
    width: 60px;
    border: 4px solid #c5141f;
    border-radius: 100%;
}

.down-arrow:before,
.down-arrow:after {
    content: '';
    position: absolute;
    top: 26px;
    width: 18px;
    border: 2px solid #c5141f;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.down-arrow:before {
    transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    left: 9px;
}

.down-arrow:after {
    transform: rotateZ(-45deg);
    -webkit-transform: rotateZ(-45deg);
    left: 22px;
}

.down-arrow:hover {
    cursor: pointer;
    border-width: 0;
}

.down-arrow:hover:before,
.down-arrow:hover:after {
    top: 40px;
}

.down-arrow:hover:before {
    left: 13px;
}

.down-arrow:hover:after {
    left: 26px;
}

#loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 5px solid rgb(51 51 51);
    border-radius: 50%;
    border-top-color: #e6e6e6;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    /* margin-left: 300px; */
    /* position: fixed; */
    top: 50%;
    left: 50%;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.setTimeUpcoming {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #b90909;
    border-radius: 30px;
}

.cursorPointer {
    cursor: pointer;
}

.spandate {
    color: #333;
    margin-left: 120px;
}
.spandatecompletmatches {
    color: #333;
    margin-left: 85px;
}
.paddingtop4 {
    padding-top: 4px;
}

.bottom-tabs {
    display: none;
}

.match_location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    color: #c5141f;
    padding-left: 5px;
    margin-top: -3px;
    margin-bottom: -3px;
}

.commentryDatetime{
    float: left;
    margin-left: 20px;
    color: #333333 !important;
    font-weight: 550;
}

.ptag{
    margin: -4px;
}

.background-section {
    background-image: url("/assets/img/scorcardHome.gif");
    background-size: cover;
    background-position: center;
    height: 100%; /* Adjust as needed */
}
/* #mainPopImage.modal {
    border-radius: 30px;
    background-color: var(--veronica-modal-bg);
    overflow-y: unset;
    height: 524px;
    max-height: 524px;
    width: 782px;
}
#mainPopImage.modal .modal-close {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #211c4d;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-text-color);
    z-index: 9;
} */
/* #mainPopImage.modal .modal-content {
    width: 100%;
    padding: 0;
    border-radius: 30px;
    overflow-y: unset;
}
#mainPopImage.modal .modal-content .modal-body {
    padding: 0;
} */

.modalheader_custompopup{
    padding: 5px 10px 5px 5px;
    border: none;
    position: absolute;
    right: 0;
    z-index: 9;
}
.modal_customwith{
    max-width: 750px;
}